/* eslint-disable vue/html-self-closing */
<template>
  <fragment>
    <el-row v-if="!uploadFinished">
      <el-row
        class="pt-20 pl-5 pr-5 sm:pl-5 sm:pr-5 md:pl-10 md:pr-10 lg:pl-20 lg:pr-20 jobs-detail-container"
      >
        <h2 class="pt-20 pb-8 text-xl">
          <b>{{ applicant.name ? `${applicant.name}` : '' }}</b>님의 추가서류를 업로드해주세요.
        </h2>
        <div class="upload-wrapper">
          <el-upload
            action=""
            drag
            multiple
            :auto-upload="false"
            :file-list="uploadList"
            :on-remove="handleUploadChange"
            :on-change="handleUploadChange"
          >
            <i class="el-icon-upload" />
            <div
              class="el-upload__text"
              v-html="$t('recruit.form.file_desc')"
            />
            <div>{{ $t('recruit.form.file_desc2') }}</div>
          </el-upload>
          <div class="mt-2 pb-20">
            <button
              :class="{ disabled: disabledUploadBtn }"
              class="customBtn lg mt-10 mb-10 fontKR"
              @click="uploadAssignment"
            >
              <span>업로드하기</span>
            </button>
          </div>
        </div>
      </el-row>
      <!-- 업로드 로딩 모달 -->
      <div
        v-show="applyLoading"
        class="loading__modal"
      >
        <div class="tsmd__dim" />
        <div class="cookie__loading">
          <div class="cookie__loading--img">
            <img
              class="cookie"
              style="width: 400px;"
              src="@/assets/recruit/recruit_loading.gif"
              alt=""
            >
          </div>
        </div>
      </div>
      <!-- // 업로드 로딩 모달 -->
    </el-row>

    <!-- 업로드 완료 페이지 -->
    <div
      v-else
      class="complete__page"
    >
      <div
        class="complete__inner"
        style="padding: 250px 2rem 230px;"
      >
        <div style="max-width: 1200px; margin: 0 auto;">
          <div class="md:inline-block md:w-2/5 md:text-right">
            <img
              class="w-100 h-100 inline-block relative"
              style="max-width: 200px;"
              src="@/assets/recruit/cookie_complete__2.png"
              alt="쿡이"
            >
          </div>
          <div class="md:inline-block align-middle md:w-3/5 text-center">
            <div class="md:ml-20 text-left">
              <div class="font-bold text-black text-2xl mt-9">
                <span class="highlight required">
                  <span class="font-bold">
                    과제 제출이 완료되었습니다.
                  </span>
                </span>
              </div>
              <div
                class="text-lg mt-6"
                style="line-height: 32px;"
              >
                제출하신 과제가 정상적으로
                <span class="font-bold">쿡앱스</span>에 전달되었습니다.<br>
                <span class="font-bold">입력해주신 이메일로</span> 과제전형의
                결과를 받아보실 수 있으십니다.
              </div>
              <button
                class="customBtn fontKR lg mt-10"
                @click="$router.push('/')"
              >
                <span>메인으로</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of 업로드 완료 페이지 -->

    <div
      v-if="showModal"
      class="share__modal inline-block"
    >
      <button
        class="share__dim"
        @click="showModal = false"
      />
      <div
        class="share__inner"
        style="width: 600px; left: calc((100vw - 600px) / 2); height: 500px; top: calc((100vh - 500px) / 2); z-index: 1;"
      >
        <button
          class="share__close__btn"
          @click="showModal = false"
        >
          <i class="xi-close" />
        </button>
        <div class="mt-10 mb-11 text-xl font-bold">
          과제 문의하기
        </div>
        <div class="mt-8 text-left">
          <el-select
            v-model="reasonType"
            clearable
            class="mr-1 mb-1"
            placeholder="수행 불가 사유"
            style="min-width: 320px"
          >
            <el-option
              v-for="r in reasons"
              :key="r.index"
              :label="r"
              :value="r"
            />
          </el-select>
          <div class="mt-5">
            <el-input
              v-model="reason"
              type="textarea"
              :rows="6"
              placeholder="사유를 간단하게 작성해 주세요. 시간 조율이 필요한 경우 채용담당자가 유선상으로 연락 드릴 예정입니다."
            />
          </div>
          <!--          <div class="ml-1 mt-6 pb-4 text-right font-normal myApply__link">
            <div @click="showModal = true;" class="ml-1 font-bold cursor-pointer">
              <span class="underline">인사담당자에게 문의하기</span>
            </div>
          </div>-->
          <div class="pt-11">
            <button
              class="customBtn mdlg block mt-10"
              style="margin: 0 auto;"
              :class="{ disabled: disabledAskBtn }"
              @click="sendQuestion"
            >
              <span style="font-size: 18px;">문의하기</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>
<script>
import axios from 'axios';
import {
  getUploadUrl,
  getDetailByUuid,
  uploadAssignment,
  sendQuestion,
} from '@/api/job';
import '@/plugins/element.js';

export default {
  name: 'SubmissionDocument',
  metaInfo: {
    title: 'Assignment',
    titleTemplate: '%s | CookApps',
  },
  data() {
    return {
      disabledUploadBtn: false,
      disabledAskBtn: false,
      applyLoading: false,
      uploadFinished: false,
      applicant: {
        name: '',
        uuid: '',
      },
      uploadList: [],
      showModal: false,
      reason: '',
      reasonType: '',
      reasons: [
        '개인 사유로 인해 과제를 못할 것 같아요',
        '지금은 과제 수행이 어려워 시간 조정이 필요해요',
        '과제 분량이 많아서 조금 더 시간이 필요할 것 같아요',
        '기타 사유',
      ],
    };
  },
  watch: {
    showModal() {
      if (!this.showModal) {
        this.reasonType = '';
        this.reason = '';
      }
    },
  },
  async mounted() {
    const result = await getDetailByUuid({ uuid: this.$route.params.uuid });

    if (!result.data.applicant) {
      // applicant 없을때 redirect
      this.$router.push('/');
    }

    this.applicant = result.data.applicant;
  },
  methods: {
    handleUploadChange(file, fileList) {
      this.uploadList = fileList;
    },
    async uploadAssignment() {
      this.disabledUploadBtn = true;
      this.applyLoading = true;
      if (!this.uploadList.length) {
        this.applyLoading = false;
        this.$message({
          message: this.$t('recruit.apply_no_file'),
          type: 'error',
        });
        return false;
      }

      const sParam = [];
      this.uploadList.forEach((file) => {
        sParam.push({
          name: file.name,
          type: file.raw.type,
        });
      });

      const signedResult = await getUploadUrl({ items: sParam });
      const uploadUrlList = signedResult.data.uploadUrlList;
      const downloadUrlList = signedResult.data.downloadUrlList;

      const promiseList = [];

      // set promise list
      uploadUrlList.forEach((url, idx) => {
        promiseList.push(
          new Promise((resolve) => {
            axios
              .put(url, this.uploadList[idx].raw, {
                headers: {
                  'Content-Type': this.uploadList[idx].raw.type,
                },
              })
              .then(() => {
                resolve(true);
              })
              .catch(() => {
                resolve(false);
              });
          })
        );
      });

      Promise.all(promiseList).then((values) => {
        if (values.indexOf(false) > -1) {
          this.$alert(
            this.$t('recruit.apply_upload_fail'),
            this.$t('recruit.upload_fail_title'),
            { dangerouslyUseHTMLString: true }
          );
        } else {
          downloadUrlList.forEach((url, idx) => {
            sParam[idx].url = url;
          });

          uploadAssignment({
            uuid: this.applicant.uuid,
            fileList: sParam,
          })
            .then((response) => {
              if (response.data.success) {
                // this.$alert(this.$t('recruit.apply_success'), this.$t('recruit.apply_success_title'));
                this.applyLoading = false;
                window.scrollTo(0, 0);
                this.uploadFinished = true;
              }
            })
            .catch(() => {
              // this.$alert(this.$t('recruit.apply_upload_fail'), this.$t('recruit.upload_fail_title'), { dangerouslyUseHTMLString: true });
            });
        }

        this.applyLoading = false;
      });
    },
    async sendQuestion() {
      this.disabledAskBtn = true;
      if (!(this.reason && this.reasonType)) {
        this.$message({
          message: '사유와 내용을 입력해주세요.',
          type: 'error',
        });
        return false;
      }

      await sendQuestion({
        reason: this.reason,
        reasonType: this.reasonType,
        uuid: this.applicant.uuid,
      });

      this.showModal = false;

      this.$message({
        message:
          '문의가 접수되었습니다. 빠른 시일 내에 유선이나 이메일을 통해 연락 드리겠습니다.',
        type: 'success',
      });
    },
  },
};
</script>
<style lang="scss">
.el-select-dropdown {
  z-index: 99999 !important;
}
</style>
<style lang="scss" scoped>
@import '@/styles/apply.scss';
.jobs-detail-container {
  text-align: center;
  margin: 2rem;

  .upload-wrapper {
    margin: auto;
    width: 360px;
  }
}
</style>
